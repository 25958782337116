import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import logoIco from "../../pictures/icons/logo.svg";

const RegisterScreen = ({ history }) => {
  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const registerHandler = async (e) => {
    e.preventDefault();

    if (password !== confirmpassword) {
      setPassword("");
      setConfirmPassword("");
      setTimeout(() => {
        setError("");
      }, 5000);
      return setError("Passwords do not match");
    }

    try {
      const { data } = await axios.post("/api/users/register", {
        companyName,
        firstName,
        lastName,
        email,
        password,
      });

      localStorage.setItem("authToken", data.token);

      window.location.href = "/";
    } catch (error) {
      setError(error.response.data.error);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  return (
    <div className="w-full h-screen">
      <form
        onSubmit={registerHandler}
        className=" text-white p-4 h-full flex justify-center items-center"
      >
        <div className="w-full lg:w-1/2 mx-auto  bg-main-bg p-5 rounded-3xl space-y-5 max-w-lg">
          <div className="flex justify-between">
            <p className="text-xl font-bold">Register and join AULIOS.</p>
            <img src={logoIco} alt="Logo" />
          </div>
          {error && (
            <span className="w-full block bg-red-500 rounded-2xl p-2">
              {error}
            </span>
          )}
          <div className="w-full">
            <input
              className="rounded-md border-2 border-white p-1 text-black w-full"
              type="text"
              id="companyName"
              placeholder="Enter company name (optional)"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
          <div className="lg:flex justify-between space-y-5 lg:space-y-0 lg:space-x-2">
            <div className="lg:w-1/2">
              <input
                className="rounded-md border-2 border-white p-1 text-black w-full"
                type="text"
                required
                id="firstName"
                placeholder="Enter first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="lg:w-1/2">
              <input
                className="rounded-md border-2 border-white p-1 text-black w-full"
                type="text"
                required
                id="lastName"
                placeholder="Enter last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div className="w-full">
            <input
              className="rounded-md border-2 border-white p-1 text-black w-full"
              type="email"
              required
              id="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="w-full">
            <input
              className="rounded-md border-2 border-white p-1 text-black w-full"
              type="password"
              required
              id="password"
              autoComplete="true"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="w-full">
            <input
              className="rounded-md border-2 border-white p-1 text-black w-full"
              type="password"
              required
              id="confirmpassword"
              autoComplete="true"
              placeholder="Confirm password"
              value={confirmpassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <hr />
          {/* add a checkbox for this "By registering, you agree to our Terms of Service and Privacy
            Policy." */}
          <div className="w-full space-x-2 items-center">
            <input type="checkbox" required id="terms" className="text-black" />
            <label htmlFor="terms" className="text-xs">
              By registering, you agree to our{" "}
              <Link to={"/tos"}>
                <span
                  style={{
                    color: "#00A5FF",
                  }}
                >
                  Terms of Service
                </span>
              </Link>{" "}
              and{" "}
              <Link to="https://www.aulios.com/privacy-notice">
                <span
                  style={{
                    color: "#00A5FF",
                  }}
                >
                  Privacy Policy.
                </span>
              </Link>
            </label>
          </div>
          <button
            type="submit"
            className="bg-white text-black rounded-2xl p-2 w-full"
          >
            Register
          </button>
          <p className="text-xs text-gray-400"></p>
          <p className="text-sm text-gray-300">
            Already have an account?{" "}
            <Link className="text-white" to="/login">
              Login
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default RegisterScreen;
