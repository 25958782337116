import axios from "axios";
import React, { useState, useEffect } from "react";

const NewSubscriptionScreen = () => {
  const [clientSecret, setClientSecret] = useState(null);
  const [tableID, setTableID] = useState(null);
  const [key, setKey] = useState(null);

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        };
        const { data } = await axios.get(
          "/api/checkout/getCustomerSession",
          config
        );
        if (data.success) {
          setClientSecret(data.data.client_secret);
        }
      } catch (error) {
        console.error("Error fetching client secret:", error);
      }
    };

    if (subdomain ==="account") {
      setTableID("prctbl_1NxURrH4GCUGIHUFiR2dpv00")
      setKey("pk_live_51KMuTuH4GCUGIHUFGTIW0RwyoVSzi5W2WJkh0e93kTgRZBtictCqoBOemtu1fLl7j8dPv0NQyCkN9dIXfQv1KgYS006bF9Zuvt")
    } else {
      setTableID("prctbl_1Nf2WlH4GCUGIHUFHPdUoV2F")
      setKey("pk_test_51KMuTuH4GCUGIHUFuXurLKN9UCha7vQUnQEDvya4doFFhHQMNTnvQI0abjfeKUCHihRQgoj6qGiRWm4yPsH6YnZ300Fn8ynOcX")
    }

    fetchClientSecret();
  }, []);

  function getSubdomain() {
    const parts = window.location.hostname.split('.');
    if (parts.length <= 2) return null; // No subdomain exists
  
   return parts[0]
  }
  
  const subdomain = getSubdomain();

  return (
    <div className="bg-stripe-bg p-2 md:p-8 min-h-screen">
      {clientSecret && tableID && key &&(
        <stripe-pricing-table
          className="h-screen"
          pricing-table-id={tableID}
          publishable-key={key}
          customer-session-client-secret={clientSecret}

        ></stripe-pricing-table>
      )}
    </div>
  );
};

export default NewSubscriptionScreen;
