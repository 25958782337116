import { useState, useEffect } from "react";
import axios from "axios";
import SideNavigation from "./SideNavigation";
import logoIco from "../../pictures/icons/logo.svg";
import {
  CreditCardIcon,
  CirclePlusIcon,
  Download,
  DownloadIcon,
} from "lucide-react";
import apple from "../../pictures/icons/apple.svg";
import windows from "../../pictures/icons/windows.svg";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../dialog";

const AccountScreen = () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };
  const [error, setError] = useState("");

  const [privateData, setPrivateData] = useState("");
  const [address, setAddress] = useState("");

  const [isCustomer, setIsCustomer] = useState(true);

  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [vatNumber, setVatNumber] = useState("");
  const [windowsURL, setWindowsURL] = useState("");
  const [macURL, setMacURL] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [surveyAnswer, setSurveyAnswer] = useState("");
  const logoutUser = () => {
    localStorage.removeItem("authToken");
    window.location.href = "/login";
  };

  const createCusomterHandler = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        "/api/checkout/createCustomer",
        {
          city,
          country,
          streetAddress,
          zipCode,
          vatNumber,
          surveyAnswer,
        },
        config
      );
      fetchPrivateData();
    } catch (error) {
      setError(error.response.data.error);
    }
  };

  const fetchPrivateData = async () => {
    try {
      const { data } = await axios.get(
        "/api/private/accountInformation",
        config
      );
      setPrivateData(data.data);
      setAddress(data.data.address);
      setIsCustomer(data.data.isCustomer);
      console.log(data.data);
    } catch (error) {
      setError(error.response.data.error);
    }
  };
  const fetchDownloadLinks = async () => {
    try {
      const { data } = await axios.get("/api/private/getLatestRelase", config);
      setWindowsURL(data.data.windowsURL);
      setMacURL(data.data.macURL);
    } catch (error) {
      setError(error.response.data.error);
    }
  };
  const fetchInvoiceDetails = async () => {
    try {
      const { data } = await axios.get("/api/private/getAllInvoices", config);
      const invoices = data.data.map((invoice) => {
        const date = new Date(invoice.date * 1000);

        const formattedDate = date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });

        return {
          invoiceId: invoice.invoiceId,
          pdf: invoice.pdf,
          date: formattedDate,
          status: invoice.status,
        };
      });
      console.log(invoices);
      setInvoices(invoices);
    } catch (error) {
      setError(error.response.data.error);
    }
  };

  const createSubscriptionScreen = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    try {
      const res = await axios.get(
        "/api/checkout/create-portal-session",
        config
      );

      window.location = res.data.url;
    } catch (error) {
      console.log(error);
    }
  };
  const createCheckoutSession = async (e) => {
    window.location.href = `/addSubscription`;
  };
  useEffect(() => {
    fetchPrivateData();
    fetchDownloadLinks();
    fetchInvoiceDetails();
  }, []);

  if (!isCustomer) {
    return (
      <div className="w-full min-h-screen">
        <form
          onSubmit={createCusomterHandler}
          className=" text-white p-4 h-full flex justify-center items-center"
        >
          <div className="w-1/2 mx-auto  bg-main-bg p-5 rounded-3xl space-y-5 max-w-lg">
            <div className="flex justify-between">
              <p className="text-xl font-bold">Complete your data</p>
              <img src={logoIco} alt="Logo" />
            </div>
            {error && (
              <span className="w-full block bg-red-500 rounded-2xl p-2">
                {error}
              </span>
            )}
            <div className="w-full">
              <input
                className="rounded-md border-2 border-white p-1 text-black w-full"
                type="text"
                required
                id="streetAddress"
                placeholder="Lohmühlenstraße 65"
                value={streetAddress}
                onChange={(e) => setStreetAddress(e.target.value)}
              />
              <label>Street, Number</label>
            </div>
            <div className="flex justify-between">
              <div className="">
                <input
                  className="rounded-md border-2 border-white p-1 text-black"
                  type="text"
                  required
                  id="city"
                  placeholder="Berlin"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                <label>City</label>
              </div>
              <div className="">
                <input
                  className="rounded-md border-2 border-white p-1 text-black w-full"
                  type="text"
                  required
                  id="zipCode"
                  placeholder="12435"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
                <label>Zip Code</label>
              </div>
            </div>
            <div className="w-full">
              <input
                className="rounded-md border-2 border-white p-1 text-black w-full"
                type="text"
                required
                id="country"
                autoComplete="true"
                placeholder="Germany"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
              <label>Country</label>
            </div>
            <div className="w-full">
              <input
                className="rounded-md border-2 border-white p-1 text-black w-full"
                type="text"
                id="vatNumber"
                autoComplete="true"
                placeholder="DE345486598"
                value={vatNumber}
                onChange={(e) => setVatNumber(e.target.value)}
              />
              <label>VAT Number(optional)</label>
            </div>
            <div className="w-full">
              <label>Where have you heard about us?</label>
              {/* Adding a dropdown to have these options  E-Mail,Google Ads,From a friend,Instagram,TikTok,LinkedIn,Other
               */}
              <select
                className="rounded-md border-2 border-white p-1 text-black w-full"
                type="text"
                id="surveyAnswer"
                required={surveyAnswer === "" ? true : false}
                placeholder="How did you hear about us?"
                value={surveyAnswer === "" ? null : surveyAnswer}
                onChange={(e) => setSurveyAnswer(e.target.value)}
              >
                <option value="">Select an option</option>
                <option value="email">E-Mail</option>
                <option value="google_ads">Google Ads</option>
                <option value="form_a_friend">From a friend</option>
                <option value="instagram">Instagram</option>
                <option value="tiktok">TikTok</option>
                <option value="linkedin">LinkedIn</option>
                <option value="other">Other</option>
              </select>
            </div>
            <hr />
            <button
              type="submit"
              className="bg-white text-black rounded-2xl p-2 w-full"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  } else if (isCustomer) {
    return (
      <div className="bg-main-bg min-h-screen  w-full flex">
        <SideNavigation />
        <div className="h-full w-full px-4 py-10 md:p-10">
          <div className="flex justify-between items-center">
            <span className="text-white text-4xl">Account</span>
            <span>
              <button
                onClick={logoutUser}
                className="flex md:hidden text-white font-semibold bg-second-bg rounded-md p-2"
              >
                Log out
              </button>
            </span>
          </div>
          <div className="flex flex-col md:flex-row space-x-2 w-full justify-between">
            <div className="bg-second-bg rounded-2xl lg:w-2/3 w-full text-white p-5 mt-10">
              <div className="flex-col w-full space-y-8">
                <div className="flex w-full justify-end">
                  <EditAccountInfo />
                </div>
                <div className="flex w-full justify-between">
                  <p className="w-1/4 text-gray-400 text-sm my-auto">
                    COMPANY NAME
                  </p>
                  <p className="font-semibold w-1/3">
                    {privateData.companyName}
                  </p>
                </div>
                <div className="flex w-full justify-between">
                  <p className="w-1/4 text-gray-400 text-sm my-auto">
                    ACCOUNT NAME
                  </p>
                  <p className="text-sm font-medium md:text-base md:font-semibold w-1/3">
                    {privateData.firstName + " " + privateData.lastName}
                  </p>
                </div>
                <div className="flex w-full justify-between">
                  <p className="w-1/4 text-gray-400 text-sm my-auto">EMAIL</p>
                  <p className="text-sm font-medium md:text-base md:font-semibold w-1/3">
                    {privateData.email}
                  </p>
                </div>
                <div className="flex w-full justify-between">
                  <p className="w-1/4 text-gray-400 text-sm my-auto">PHONE</p>
                  <p className="text-sm font-medium md:text-base md:font-semibold w-1/3">
                    {privateData.phoneNumber
                      ? privateData.phoneNumber
                      : "Not added"}
                  </p>
                </div>
                <div className="flex w-full justify-between">
                  <p className="w-1/4 text-gray-400 text-sm my-auto">ADDRESS</p>
                  <p className="text-sm font-medium md:text-base md:font-semibold w-1/3">
                    {address ? address : "Not added"}
                  </p>
                </div>
                <div className="flex w-full justify-between">
                  <p className="w-1/4 text-gray-400 text-sm my-auto">
                    VAT number
                  </p>
                  <p className="text-sm font-medium md:text-base md:font-semibold w-1/3">
                    {privateData.vatNumber
                      ? privateData.vatNumber
                      : "Not added"}
                  </p>
                </div>
              </div>
            </div>
            <div className="rounded-2xl lg:w-1/3  w-full text-white mt-10 grid gap-y-5">
              <div className="bg-second-bg rounded-2xl w-full text-white px-5 flex flex-col justify-evenly space-y-2">
                <div className="flex justify-center items-center">
                  <p className="text-xl">Subscription</p>
                </div>
                <div className="flex flex-col w-full justify-center items-center space-y-2">
                  <button
                    onClick={createSubscriptionScreen}
                    className="text-white bg-black w-full rounded-md p-2"
                  >
                    <span className="flex justify-center space-x-3 w-full">
                      <CreditCardIcon className="h-6 w-6" />
                      <p>Subscription</p>
                    </span>
                  </button>
                  <button
                    onClick={createCheckoutSession}
                    className="text-white bg-black w-full rounded-md p-2"
                  >
                    <span className="flex justify-center space-x-3 w-full">
                      <CirclePlusIcon className="h-6 w-6" />
                      <p>Get Subscription</p>
                    </span>
                  </button>
                </div>
              </div>
              <div className="bg-second-bg rounded-2xl w-full text-white px-5 flex flex-col justify-evenly space-y-2">
                <div className="flex justify-center items-center">
                  <p className="text-xl">Download</p>
                </div>
                <div className="flex flex-col w-full justify-center items-center space-y-2">
                  <button
                    href={windowsURL}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(windowsURL, "_blank");
                    }}
                    className="text-white bg-black w-full rounded-md p-2"
                  >
                    <span className="flex justify-center space-x-3 w-full">
                      <img src={windows} alt="windows" className="h-6 w-6 " />
                      <p>Windows</p>
                    </span>
                  </button>
                  <button
                    href={macURL}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(macURL, "_blank");
                    }}
                    className="text-white bg-black w-full rounded-md p-2"
                  >
                    <span className="flex justify-center space-x-3 w-full">
                      <img src={apple} alt="apple" className="h-6 w-6" />
                      <p>Mac</p>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-second-bg rounded-2xl lg:w-2/3 w-full text-white p-5 mt-10">
            {invoices.length > 0 ? (
              <div className="flex flex-col w-full space-y-5">
                <p className="text-xl">Invoices</p>
                <div className="flex justify-between w-full">
                  <span className="w-1/3 flex justify-start items-center">
                    <p className="text-gray-400">Date</p>
                  </span>
                  <span className="w-1/3 flex justify-center items-center">
                    <p className="text-gray-400">Status</p>
                  </span>
                  <span className="w-1/3 flex justify-center items-center">
                    <p className="text-gray-400">Download</p>
                  </span>
                </div>
                {invoices.map((invoice) => (
                  <div
                    key={invoice.invoiceId}
                    className="flex justify-between w-full"
                  >
                    <span className="w-1/3 flex justify-start items-center">
                      <p>{invoice.date}</p>
                    </span>
                    <span className="w-1/3 flex justify-center items-center">
                      <p>
                        {invoice.status === "paid" ? (
                          <span className="bg-green-500/40 text-green-400 px-2 py-0.5 rounded-md">
                            Paid
                          </span>
                        ) : (
                          <span className="bg-red-500/40 text-red-400 px-2 py-0.5 rounded-md">
                            Unpaid
                          </span>
                        )}
                      </p>
                    </span>
                    <span className="w-1/3 flex justify-center items-center">
                      <a
                        href={invoice.pdf}
                        target="_blank"
                        rel="noreferrer"
                        className="text-white"
                      >
                        <DownloadIcon className="h-6 w-6" />
                      </a>
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex justify-center items-center">
                <p>No invoices found</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

const EditAccountInfo = () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  const [vatNumber, setVatNumber] = useState("");
  const fetchPrivateData = async () => {
    try {
      const { data } = await axios.get(
        "/api/private/accountInformation",
        config
      );

      setFirstName(data.data.firstName);
      setLastName(data.data.lastName);
      setCity(data.data.city);
      setCountry(data.data.country);
      setStreetAddress(data.data.streetName + " " + data.data.streetNumber);
      setZipCode(data.data.zipcode);
      setVatNumber(data.data.vatNumber);
      setPhoneNumber(data.data.phoneNumber ? data.data.phoneNumber : "");
    } catch (error) {
      setError(error.response.data.error);
    }
  };

  const updateAccountInformationHandler = async (e) => {
    e.preventDefault();
    // if (phoneNumber !== "") {
    //   const phoneRegex = /\+?([\d|\(][\h|\(\d{3}\)|\.|\-|\d]{4,}\d)/;
    //   if (!phoneRegex.test(e.target.value)) {
    //     setError("Please provide a valid phone number");
    //     return;
    //   }
    // }

    try {
      await axios.put(
        "/api/users/updateAccountInformation",
        {
          firstName,
          lastName,
          city,
          country,
          streetAddress,
          zipCode,
          vatNumber,
          phoneNumber,
        },
        config
      );
      setOpen(false);
      // reload the page
      window.location.reload();
    } catch (error) {
      setError(error.response.data.error);
    }
  };
  useEffect(() => {
    fetchPrivateData();
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <button className="text-white bg-black w-full rounded-md p-2">
          Edit Account
        </button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[450px] bg-black text-white">
        <DialogHeader>
          <DialogTitle>Edit profile</DialogTitle>
          <DialogDescription>
            Make changes to your profile here. Click save when you're done.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          {error && (
            <span className="w-full block bg-red-500 rounded-2xl p-2">
              {error}
            </span>
          )}
          <div className="flex space-x-2">
            <div className="flex flex-col">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="col-span-3 p-2 rounded-md border border-gray-300 text-black"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="col-span-3 p-2 rounded-md border border-gray-300 text-black"
              />
            </div>
          </div>
          <div className="w-full">
            <label>Street, Number</label>
            <input
              className="rounded-md border-2 border-white p-1 text-black w-full"
              type="text"
              required
              id="streetAddress"
              placeholder="Lohmühlenstraße 65"
              value={streetAddress}
              onChange={(e) => setStreetAddress(e.target.value)}
            />
          </div>
          <div className="flex justify-between space-x-2">
            <div className="">
              <label>City</label>
              <input
                className="rounded-md border-2 border-white p-1 text-black"
                type="text"
                required
                id="city"
                placeholder="Berlin"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="">
              <label>Zip Code</label>
              <input
                className="rounded-md border-2 border-white p-1 text-black w-full"
                type="text"
                required
                id="zipCode"
                placeholder="12435"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </div>
          </div>
          <div className="w-full">
            <label>Country</label>
            <input
              className="rounded-md border-2 border-white p-1 text-black w-full"
              type="text"
              required
              id="country"
              autoComplete="true"
              placeholder="Germany"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </div>
          <div className="w-full">
            <label>{"Phone Number (optional)"}</label>
            <input
              className="rounded-md border-2 border-white p-1 text-black w-full"
              type="text"
              id="phoneNumber"
              autoComplete="true"
              placeholder="+49 1234567890"
              value={phoneNumber}
              onChange={(e) => {
                // setError("");
                setPhoneNumber(e.target.value);
              }}
            />
          </div>
          <div className="w-full">
            <label>VAT Number (optional)</label>
            <input
              className="rounded-md border-2 border-white p-1 text-black w-full"
              type="text"
              required
              id="vatNumber"
              autoComplete="true"
              placeholder="DE345486598"
              value={vatNumber}
              onChange={(e) => setVatNumber(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-end space-x-2">
          <button
            onClick={() => setOpen(false)}
            className="bg-red-500 text-black rounded-md p-2"
          >
            Cancel
          </button>
          <button
            onClick={updateAccountInformationHandler}
            className="bg-white text-black rounded-md p-2"
          >
            Save
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AccountScreen;
