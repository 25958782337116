import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import logo from "../../pictures/logo.png";
import logoVertical from "../../pictures/logo-vertical.png";
import accountIco from "../../pictures/icons/account-white.svg";
import subscricptionIco from "../../pictures/icons/subscription-white.svg";
import settingsIco from "../../pictures/icons/settings-white.svg";
import downloadIco from "../../pictures/icons/file_download_black_24dp.svg";
import logoutIco from "../../pictures/icons/logout-white.svg";

const SideNavigation = () => {
  const [isConfirmed, setIsConfirmed] = useState(true);
  const [error, setError] = useState("");
  const [emailSend, setEmailSend] = useState(false);

  const logoutUser = () => {
    localStorage.removeItem("authToken");
    window.location.href = "/login";
  };
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  const resendEmail = async () => {
    try {
      if (emailSend) return;

      const res = await axios.get("/api/users/resendEmail", config);
      setEmailSend(true);
    } catch (error) {
      setEmailSend(false);
    }
  };

  const checkEmailIsConfirmed = async () => {
    try {
      const { data } = await axios.get("/api/users/isEmailConfirmed", config);
      setIsConfirmed(data.data.isConfirmed);
    } catch (error) {
      setError(error.response.data.error);
      if (error.response.data.error === "Not authorized to acces this route") {
        logoutUser();
      }
    }
  };

  useEffect(() => {
    checkEmailIsConfirmed();
  });

  return (
    <div className="hidden md:flex max-w-1/6 min-h-full bg-bg-side flex-col px-8 py-2 justify-evenly">
      <div className="flex flex-grow">
        <div className="p-2 text-white relative flex items-center justify-center">
          <img src={logoVertical} alt="AULIOS" className=""></img>
        </div>

        {/* Add other elements here as needed */}
      </div>
      <div className="w-full">
        {!isConfirmed && (
          <div className="w-full bg-red-500 rounded-xl text-center p-2 mb-2">
            <p>Please confirm your email</p>
            <p className="text-sm">Click the link in the email you received.</p>
            <button className="text-base" onClick={resendEmail}>
              Resend email
            </button>
          </div>
        )}
        <hr />
        <div className="flex space-x-4 pt-2">
          <img src={logoutIco} alt="Account" className="my-auto h-6"></img>
          <button className="text-white" onClick={logoutUser}>
            Log out
          </button>
        </div>
      </div>
    </div>
  );
};
export default SideNavigation;
