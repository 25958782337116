import { useState } from "react";
import axios from "axios";
import logoIco from "../../pictures/icons/logo.svg";

const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const forgotPasswordHandler = async (e) => {
    e.preventDefault();

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axios.post(
        "/api/users/forgotpassword",
        { email },
        config
      );
      
      setSuccess(data.message);
    } catch (error) {
      setError(error.response.data.error);
      setEmail("");
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  return (
    <div className="w-full h-screen">
      <form
        onSubmit={forgotPasswordHandler}
        className="text-white p-4 h-full flex justify-center items-center"
      >
        <div className="w-full lg:w-1/2 mx-auto  bg-main-bg p-5 rounded-3xl space-y-5 max-w-lg">
          <div className="flex justify-between">
            <p className="text-xl font-bold">Forgot Password</p>
            <img src={logoIco} alt="Logo" />
          </div>
          {error && (
            <span className="w-full block bg-red-500 rounded-2xl p-2">
              {error}
            </span>
          )}
          {success && (
            <span className="w-full block bg-green-500 rounded-2xl p-2">
              {success}
            </span>
          )}
          <div className="w-full">
            <p className="">
              Please enter the email address you register your account with. 
            </p>
              <p>We will send your reset password confirmation to this email.</p>
            <input
              type="email"
              required
              id="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="rounded-md border-2 mt-2 border-white p-1 text-black w-full"
            />
          </div>
          <hr/>
          <button
            type="submit"
            className="bg-white text-black rounded-2xl p-2 w-full"
          >
            Send Email
          </button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPasswordScreen;
