import axios from "axios";
import React, { useState, useEffect } from "react";
import SideNavigation from "./SideNavigation";
import subscriptionIco from "../../pictures/icons/card-circle.svg";
import plusIco from "../../pictures/icons/plus-circle.svg";

const SubscriptionModal = ({
  onClose,
  subscriptions,
  oneTimeProducts,
  assignSubscription,
}) => {
  const [currentView, setCurrentView] = useState("subscriptions");

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded-lg w-3/4">
        <button onClick={onClose} className="float-right">
          Close
        </button>
        <div className="mb-4">
          <button
            onClick={() => setCurrentView("subscriptions")}
            className="mr-4"
          >
            Subscriptions
          </button>
          <button onClick={() => setCurrentView("oneTimeProducts")}>
            One-Time Products
          </button>
        </div>
        {currentView === "subscriptions" ? (
          // Render subscriptions table
          <div>
            <h2 className="text-xl mb-4">Available Subscriptions</h2>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Start Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.map((sub) => (
                  <tr key={sub.ID}>
                    <td>{sub.productName}</td>
                    <td>{new Date(sub.startDate).toLocaleString()}</td>
                    <td>
                      {sub.activeDeviceId ? (
                        "Already Assigned"
                      ) : (
                        <button onClick={() => assignSubscription(sub.ID)}>
                          Assign to Device
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Purchase Date</th>{" "}
                {/* Adjust this if the property name is different */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {oneTimeProducts.map((product) => (
                <tr key={product.ID}>
                  <td>{product.productName}</td>
                  <td>
                    {new Date(product.purchaseDate).toLocaleString()}
                  </td>{" "}
                  {/* Adjust this if the property name is different */}
                  <td>
                    {product.activeDeviceId ? (
                      "Already Assigned"
                    ) : (
                      <button onClick={() => assignSubscription(product.ID)}>
                        Assign to Device
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

const calculateTime = (milliseconds) => {
  const days = Math.floor(milliseconds / (24 * 60 * 60 * 1000));
  const hours = Math.floor(
    (milliseconds % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
  );

  return { days, hours };
};

const ExpirationInfo = ({ expirationDate, expirationTime }) => {
  if (expirationDate) {
    const isExpired = new Date() > new Date(expirationDate);
    return (
      <p className={isExpired ? "text-red-500" : "text-green-400"}>
        Active till: {new Date(expirationDate).toLocaleString()}
        ({isExpired ? "Expired" : "Active"})
      </p>
      
    );
  } else if (expirationTime) {
    const { days, hours } = calculateTime(expirationTime);
    return (
      <p className="text-lime-600">
        Not started, {days}d: {hours}h usage
      </p>
    );
  } else {
    return null;
  }
};

const SubscriptionScreen = () => {
  const [listActiveDevices, setListActiveDevices] = useState([]);
  const [error, setError] = useState("");

  const [subscriptions, setSubscriptions] = useState([]);
  const [oneTimeProducts, setOneTimeProducts] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState("");

  const [showModal, setShowModal] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };

  const getDeviceInfo = async (pcId) => {
    try {
      const { data } = await axios.post(
        "/api/private/getDeviceInfo",
        { pcId },
        config
      );
      console.log(data.data);
    } catch (error) {}
  };

  const activateSubscription = async (pcId, subId) => {
    try {
      const { data } = await axios.post(
        "/api/device/assignSubscription",
        { pcId, id: subId },
        config
      );
      fetchPrivateDate();
      fetchSubscriptions();
      fetchOneTimeProducts();
    } catch (error) {
      setError(error.response.data.error);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  const fetchPrivateDate = async () => {
    try {
      const { data } = await axios.get(
        "/api/private/getRegisteredDevices",
        config
      );

      setListActiveDevices(
        data.data.map((device) => (
          <tr key={device.id}>
            <td>{device.pcName}</td>
            <td>
              {device.subInfo.productName
                ? device.subInfo.productName
                : device.subInfo.error}
            </td>
            <td>
            {
  device.subInfo.productName ? (
    <>
      <ExpirationInfo
        expirationDate={device.subInfo.expirationDate}
        expirationTime={device.subInfo.expirationTime}
      />
      {new Date() > new Date(device.subInfo.expirationDate) && (
        <button
          type="button"
          className="bg-white text-black p-2 px-5 rounded-xl mt-4 block"
          onClick={() => {
            setShowModal(true);
            setSelectedDeviceId(device.pcId);
          }}
        >
          assign subscription
        </button>
      )}
    </>
  ) : (
    <button
      type="button"
      className="bg-white text-black p-2 px-5 rounded-xl mt-4 block"
      onClick={() => {
        setShowModal(true);
        setSelectedDeviceId(device.pcId);
      }}
    >
      assign subscription
    </button>
  )
}
            </td>
            <td>
              <button
                type="button"
                className="bg-white text-black p-2 px-5 rounded-xl mt-4 block mx-auto"
                onClick={() => getDeviceInfo(device.pcId)}
              >
                Info
              </button>
            </td>
          </tr>
        ))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPrivateDate();
    fetchSubscriptions();
    fetchOneTimeProducts();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const { data } = await axios.get("/api/private/getSubscription", config);
      setSubscriptions(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOneTimeProducts = async () => {
    try {
      const { data } = await axios.get("/api/private/oneTimeProducts", config);
      setOneTimeProducts(data.data);
    } catch (error) {
      console.log(error);
    }
  };


  const createSubscriptionScreen = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    try {
      const res = await axios.get(
        "/api/checkout/create-portal-session",
        config
      );

      window.location = res.data.url;
    } catch (error) {
      console.log(error);
    }
  };

  const createCheckoutSession = async (e) => {
    window.location.href = `/addSubscription`;
  };

  return (
    <div className="bg-main-bg min-h-screen min-w-full flex">
      <SideNavigation />
      <div className="h-full w-full p-10">
        <p className="text-white text-4xl">Subscriptions</p>
        <div className="w-full lg:flex justify-between lg:space-x-8 text-white text-2xl mt-16 space-y-5 lg:space-y-0">
          <div
            className="bg-second-bg rounded-xl flex-grow p-6 hover:cursor-pointer"
            onClick={createSubscriptionScreen}
          >
            <p>Manage subscriptions</p>
            <img
              className="mx-auto m-4 h-24 "
              src={subscriptionIco}
              alt="Subscription"
            />
          </div>
          <div
            className="bg-second-bg rounded-xl flex-grow p-6  hover:cursor-pointer"
            onClick={createCheckoutSession}
          >
            <p>Add new subscription</p>
            <img
              className="mx-auto m-4 h-24"
              src={plusIco}
              alt="Subscription"
            />
          </div>
        </div>
        <div className="w-full bg-second-bg rounded-xl mt-8 text-white p-4">
          <table className="w-full text-left">
            <thead>
              <tr>
                <th>Registered devices</th>
                <th>Plan</th>
                {error && <th className="text-red-500">{error}</th>}
                {!error && <th> </th>}
                <th> </th>
              </tr>
            </thead>
            <tbody>{listActiveDevices}</tbody>
          </table>
        </div>
        {showModal && (
          <SubscriptionModal
            onClose={() => setShowModal(false)}
            subscriptions={subscriptions}
            oneTimeProducts={oneTimeProducts}
            assignSubscription={(subId) => {
              // Close the modal after assigning
              setShowModal(false);
              activateSubscription(selectedDeviceId, subId); // You'll need to keep track of the selected device ID
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SubscriptionScreen;
