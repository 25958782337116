export default function TOSComponenet() {
  return (
    <iframe
      src="./terms-of-service.html"
      title="Terms of Service"
      width="100%"
      height={window.innerHeight}
    />
  );
}

