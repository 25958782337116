import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import logoIco from "../../pictures/icons/logo.svg";

const LoginScreen = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      window.location.href = "/";
    }
  }, [history]);

  const loginHandler = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post("/api/users/login", {
        email,
        password,
      });

      localStorage.setItem("authToken", data.token);

      window.location.href = "/";
    } catch (error) {
      setError(error.response.data.error);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  return (
    <div className="w-full h-screen">
      <form
        onSubmit={loginHandler}
        className="text-white p-4 h-full flex justify-center items-center"
      >
        <div className="w-full md:w-1/2 mx-auto  bg-main-bg p-5 rounded-3xl space-y-5 max-w-lg">
          <div className="flex justify-between">
            <p className="text-xl font-bold">Login</p>
            <img src={logoIco} alt="Logo" />
          </div>
          {error && (
            <span className="w-full block bg-red-500 rounded-2xl p-2">
              {error}
            </span>
          )}
          <div className="w-full">
            <input
              type="email"
              required
              id="email"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              tabIndex={1}
              className="rounded-md border-2 border-white p-1 text-black w-full"
            />
          </div>
          <div className="w-full">
            <input
              type="password"
              required
              id="password"
              autoComplete="true"
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              tabIndex={2}
              className="rounded-md border-2 border-white p-1 text-black w-full"
            />
            <Link to="/forgotpassword" className="text-sm text-gray-300">
              Forgot Password?
            </Link>
          </div>
          <hr/>
          <button
            type="submit"
            className="bg-white text-black rounded-2xl p-2 w-full"
          >
            Login
          </button>

          <p className="text-sm text-gray-300">
            Don't have an account?{" "}
            <Link className="text-white" to="/register">
              Register
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default LoginScreen;
