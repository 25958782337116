import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import logoIco from "../../pictures/icons/logo.svg";

const ResetPasswordScreen = ({ history, match }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const params = useParams();

  console.log(params.resetToken);

  const resetPasswordHandler = async (e) => {
    e.preventDefault();

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    if (password !== confirmPassword) {
      setPassword("");
      setConfirmPassword("");
      setTimeout(() => {
        setError("");
      }, 5000);
      return setError("Passwords don't match");
    }

    try {
      const { data } = await axios.post(
        `/api/users/resetpassword/${params.resetToken}`,
        {
          password,
        },
        config
      );

      setSuccess(data.message);
      setPassword("");
      setConfirmPassword("");
    } catch (error) {
      setError(error.response.data.error);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  return (
    <div className="w-full h-screen">
      <form
        onSubmit={resetPasswordHandler}
        className="text-white p-4 h-full flex justify-center items-center"
      >
        <div className="w-full lg:w-1/2 mx-auto  bg-main-bg p-5 rounded-3xl space-y-5 max-w-lg">
          <div className="flex justify-between">
            <p className="text-xl font-bold">Forgot Password</p>
            <img src={logoIco} alt="Logo" />
          </div>
          {error && (
            <span className="w-full block bg-red-500 rounded-2xl p-2">
              {error}
            </span>
          )}
          {success && (
            <span className="w-full block bg-green-500 rounded-2xl p-2">
              {success}
            </span>
          )}
          <div className="w-full">
            <input
              type="password"
              required
              id="password"
              placeholder="Enter new password"
              autoComplete="true"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="rounded-md border-2 mt-2 border-white p-1 text-black w-full"
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              required
              id="confirmpassword"
              placeholder="Confirm new password"
              autoComplete="true"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="rounded-md border-2 mt-2 border-white p-1 text-black w-full"
            />
          </div>
          <hr />
          <button
            type="submit"
            className="bg-white text-black rounded-2xl p-2 w-full"
          >
            Reset Password
          </button>
            <p className="text-gray-300 text-sm">
              Back to&nbsp;
              <Link className="text-white"  to="/login">
                 login
              </Link>
            </p>
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordScreen;
