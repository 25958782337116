import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useEffect } from "react";
import Hotjar from "@hotjar/browser";

import AccountScreen from "./components/screens/AccountScreen";
import SubscriptionScreen from "./components/screens/SubscriptionScreen";
import LoginScreen from "./components/screens/LoginScreen";
import RegisterScreen from "./components/screens/RegisterScreen";
import ForgotPasswordScreen from "./components/screens/ForgotPasswordScreen";
import ResetPasswordScreen from "./components/screens/ResetPasswordScreen";
import ConfirmEmailScreen from "./components/screens/ConfirmEmailScreen";
import NewSubscriptionScreen from "./components/screens/NewSubscriptionScreen";
import AgbScreen from "./components/screens/AGB";
import TagManager from "react-gtm-module";
import TOSComponenet from "./components/screens/ToS";

const App = () => {
  console.log(window.location.hostname);

  useEffect(() => {
    if (window.location.hostname === "account.aulios.com") {
      const tagManagerArgs = {
        gtmId: "GTM-WNHW9CB",
      };
      TagManager.initialize(tagManagerArgs);

      const siteId = 4957095;
      const hotjarVersion = 6;
      Hotjar.init(siteId, hotjarVersion);
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Navigate to="/account" />} />
          <Route exact path="/account" element={<AccountScreen />} />
          <Route exact path="/subscriptions" element={<SubscriptionScreen />} />
          <Route
            exact
            path="/addSubscription"
            element={<NewSubscriptionScreen />}
          />
          <Route exact path="/login" element={<LoginScreen />} />
          <Route exact path="/register" element={<RegisterScreen />} />
          <Route
            exact
            path="/forgotpassword"
            element={<ForgotPasswordScreen />}
          />
          <Route
            exact
            path="/resetpassword/:resetToken"
            element={<ResetPasswordScreen />}
          />
          <Route
            exact
            path="/confirmEmail/:confirmationToken"
            element={<ConfirmEmailScreen />}
          />
          <Route exact path="/agb" element={<AgbScreen />} />
          <Route exact path="/tos" element={<TOSComponenet />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
