import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const ConfirmEmailScreen = ({ history, match }) => {
  const [error, setError] = useState("");

  const params = useParams();

  const resetPasswordHandler = async () => {
    try {
      const { data } = await axios.post(
        `/api/users/confirmEmail/${params.confirmationToken}`
      );

      window.location.href = "/";
    } catch (error) {
        setError(error.response.data.error);
    }
  };
  useEffect(() => {
    resetPasswordHandler();
  });

  return <div className="w-full h-screen">
      {error && (
            <span className="w-full block bg-red-500 rounded-2xl p-2">
              {error}
            </span>
          )}
  </div>;
};

export default ConfirmEmailScreen;
